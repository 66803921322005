<template>
  <base-material-card
    :padding-heading="3"
    color="primary"
    width-heading="33%"
  >
    <template v-slot:heading>
      <div
        class="display-2 font-weight-light ma-auto"
        style="height: 48px; line-height: 48px"
      >
        Editar Empresa
      </div>
    </template>
    <v-form
      @submit.prevent="updateEmpresa()"
      ref="form"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              :rules="[rules.required, rules.maxLength(50)]"
              autocomplete="off"
              color="white"
              label="Nombre"
              v-model="empresa_card.nombre"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              :rules="[rules.maxLength(255), rules.required]"
              auto-grow
              class="overflow-y-hidden"
              color="white"
              label="Descripción"
              rows="1"
              v-model="empresa_card.descripcion"
            />
          </v-col>

          <v-col
            class="text-right py-0"
            cols="12"
          >
            <v-btn
              :loading="botonesLoading.perfil"
              class="mr-0"
              color="primary"
              type="submit"
            >
              Actualizar empresa
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-material-card>
</template>
<script>
  import { email, maxLength, required } from '../../../../utils/rules'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'editarPerfil',
    data: () => ({
      botonesLoading: {
        perfil: false,
      },
      empresa_card: {
        nombre: '',
        descripcion: '',
      },
      rules: {
        maxLength,
        required,
        email,
      },
    }),
    computed: {
      ...mapState('perfil', ['empresa']),
    },
    methods: {
      ...mapActions('perfil', ['putEmpresa']),
      updateEmpresa () {
        const isValid = this.$refs.form.validate()
        if (!isValid) {
          return
        }
        this.botonesLoading.perfil = true
        const empresa = {
          nombre: this.empresa_card.nombre,
          descripcion: this.empresa_card.descripcion,
        }

        this.putEmpresa(empresa).then(() => {
          this.$toast.success('Se actualizó la información correctamente.')
        }).finally(() => {
          this.botonesLoading.perfil = false
        })
      },

    },
    watch: {
      empresa: function () {
        this.empresa_card.nombre = this.empresa.nombre
        this.empresa_card.descripcion = this.empresa.descripcion
      },
    },
    mounted () {
      if (this.empresa == null) return false
      this.empresa_card.nombre = this.empresa.nombre
      this.empresa_card.descripcion = this.empresa.descripcion
    },
  }
</script>
