<template>
  <base-material-card
    :padding-heading="3"
    width-heading="33%"
    color="primary"
  >
    <template v-slot:heading>
      <div
        class="display-2 font-weight-light ma-auto"
        style="height: 48px; line-height: 48px"
      >
        Editar Perfil
      </div>
    </template>
    <v-form
      @submit.prevent="updateUser()"
      ref="form"
    >
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :rules="[rules.required, rules.maxLength(50)]"
              autocomplete="off"
              color="white"
              label="Nombres"
              v-model="user_card.first_name"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :rules="[rules.required, rules.maxLength(50)]"
              autocomplete="off"
              color="white"
              label="Apellidos"
              v-model="user_card.last_name"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :rules="[rules.required, rules.maxLength(15), rules.cellphone,]"
              autocomplete="off"
              color="white"
              label="Celular/Telefono"
              v-model="user_card.telefono"
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              :rules="[rules.required, rules.email, rules.maxLength(50)]"
              autocomplete="off"
              color="white"
              label="Correo electrónico"
              v-model="user_card.email"
            />
          </v-col>
          <v-col
            class="text-right"
            cols="12"
          >
            <v-btn
              :loading="botonesLoading.perfil"
              class="mr-0"
              color="primary"
              type="submit"
            >
              Actualizar perfil
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-material-card>
</template>
<script>
  import { cellphone, email, maxLength, required } from '../../../../utils/rules'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'editarPerfil',
    data: () => ({
      botonesLoading: {
        perfil: false,
      },
      user_card: {
        first_name: '',
        telefono: '',
        last_name: '',
        email: '',
      },
      rules: {
        maxLength,
        required,
        email,
        cellphone,
      },
    }),
    computed: {
      ...mapState('perfil', ['user']),
    },
    methods: {
      ...mapActions('perfil', ['fetchUser', 'putUser']),
      updateUser () {
        const isValid = this.$refs.form.validate()
        if (!isValid) {
          return
        }
        this.botonesLoading.perfil = true
        const user = {
          first_name: this.user_card.first_name,
          telefono: this.user_card.telefono,
          email: this.user_card.email,
          last_name: this.user_card.last_name,
        }
        this.putUser(user).then(() => {
          this.$toast.success('Se actualizó la información correctamente.')
        }).catch(() => {
          // this.$toast.error('No se pudo actualizar su información, intentelo nuevamente en unos momentos.')
        }).finally(() => {
          this.botonesLoading.perfil = false
        })
      },

    },
    watch: {
      user: function () {
        this.user_card.first_name = this.user.first_name
        this.user_card.last_name = this.user.last_name
        this.user_card.telefono = this.user.telefono
        this.user_card.email = this.user.email
      },
    },
  }
</script>
