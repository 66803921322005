<template>
  <base-material-card
    :padding-heading="3"
    class="px-5 py-3 "
    color="primary"
    v-if="empresa"
  >
    <template v-slot:heading>
      <v-card-title>
        <span
          class="display-2 font-weight-light ma-auto"
          style="height: 36px; line-height: 36px"
        >
          Alertar a:
        </span>
      </v-card-title>
      <v-tabs
        active-class="white--text"
        background-color="transparent"
        class="white--text ml-sm-n6 ml-lg-0"
        grow
        show-arrows
        slider-color="white"
        v-model="tab"
      >
        <v-tab
          class="mr-3"
          v-if="empresa.notificaciones_sms"
        >
          <v-icon class="mr-2">
            mdi-message-text
          </v-icon>
          SMS
        </v-tab>
        <v-tab
          class="mr-3"
          v-if="empresa.notificaciones_wa"
        >
          <v-icon class="mr-2">
            mdi-whatsapp
          </v-icon>
          Whatsapp
        </v-tab>
        <v-tab
          class="mr-3"
          v-if="empresa.notificaciones_correo"
        >
          <v-icon class="mr-2">
            mdi-email
          </v-icon>
          Correo
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items
      class="transparent"
      v-model="tab"
    >
      <v-tab-item v-if="empresa.notificaciones_sms">
        <v-card-text>
          <v-form
            @submit.prevent="updateMediosNotificacion('sms')"
            ref="formNotificaciones_sms"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  :key="n"
                  cols="12"
                  v-for="n in empresa.notificaciones_sms"
                >
                  <v-text-field
                    :label="`Número ${n}`"
                    :rules="[rules.cellphone]"
                    autocomplete="off"
                    color="white"
                    v-mask="'+###########'"
                    v-model="notificaciones_sms[n-1]"
                  />
                </v-col>
                <v-col
                  class="text-right"
                  cols="12"
                >
                  <v-btn
                    :loading="botonesLoading.notificaciones_sms"
                    class="mr-0"
                    color="primary"
                    type="submit"
                  >
                    Actualizar números
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-tab-item>
      <v-tab-item v-if="empresa.notificaciones_wa">
        <v-card-text>
          <v-form
            @submit.prevent="updateMediosNotificacion('wa')"
            ref="formNotificaciones_wa"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  :key="n"
                  cols="12"
                  v-for="n in empresa.notificaciones_wa"
                >
                  <v-text-field
                    :label="`Número ${n}` "
                    autocomplete="off"
                    color="white"
                    v-mask="'+## ### ### ###'"
                    v-model="notificaciones_wa[n-1]"
                  />
                </v-col>
                <v-col
                  class="text-right"
                  cols="12"
                >
                  <v-btn
                    :loading="botonesLoading.notificaciones_wa"
                    class="mr-0"
                    color="primary"
                    type="submit"
                  >
                    Actualizar números
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-tab-item>
      <v-tab-item v-if="empresa.notificaciones_correo">
        <v-card-text>
          <v-form
            @submit.prevent="updateMediosNotificacion('correo')"
            ref="formNotificaciones_correo"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  :key="n"
                  cols="12"
                  v-for="n in empresa.notificaciones_correo"
                >
                  <v-text-field
                    :label="`Correo ${n}`"
                    :rules="[rules.email]"
                    autocomplete="off"
                    color="white"
                    v-model="notificaciones_correo[n-1]"
                  />
                </v-col>
                <v-col
                  class="text-right"
                  cols="12"
                >
                  <v-btn
                    :loading="botonesLoading.notificaciones_correo"
                    class="mr-0"
                    color="primary"
                    type="submit"
                  >
                    Actualizar correos
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </base-material-card>

</template>

<script>
  import { cellphone, email, maxLength, required } from '../../../../utils/rules'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'editarAlarmas.vue',
    data: () => ({
      tab: 0,
      botonesLoading: {
        notificaciones_correo: false,
        notificaciones_wa: false,
        notificaciones_sms: false,
      },
      notificaciones_correo: [],
      notificaciones_sms: [],
      notificaciones_wa: [],
      rules: {
        maxLength,
        required,
        email,
        cellphone,
      },
    }),
    computed: {
      ...mapState('perfil', ['empresa', 'medios_notificacion_wa', 'medios_notificacion_sms', 'medios_notificacion_correo']),
    },
    watch: {},
    async beforeMount () {
      await this.fetchMediosNotificacion()
      this.notificaciones_correo = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_correo)
      this.notificaciones_wa = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_wa)
      this.notificaciones_sms = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_sms)
    },
    methods: {
      ...mapActions('perfil', ['fetchMediosNotificacion', 'putMediosNotificacion']),
      fromArrayNotificacion2ArrayString (arrayNotificacion) {
        return arrayNotificacion.map(({ id, medio, numero_direccion }) => numero_direccion)
      },
      fromArrayString2ArrayNotificacion (medio, arrayString) {
        return arrayString.filter((numero_direccion) => numero_direccion !== '')
          .map((numero_direccion) => numero_direccion.trim())
          .map((numero_direccion) => {
            return {
              medio,
              numero_direccion,
            }
          })
      },
      async updateMediosNotificacion (medio) {
        let payload, medios_notificacion
        try {
          switch (medio) {
            case 'sms':
              this.botonesLoading.notificaciones_correo = true
              medios_notificacion = this.fromArrayString2ArrayNotificacion('sms', this.notificaciones_sms.slice(0, this.empresa.notificaciones_sms))
              payload = {
                medio: 'sms',
                medios_notificacion,
              }
              await this.putMediosNotificacion(payload)
              this.$toast.success('Se actualizó la información correctamente.')
              this.notificaciones_sms = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_sms)
              this.$refs.formNotificaciones_sms.resetValidation()
              break
            case 'wa':
              medios_notificacion = this.fromArrayString2ArrayNotificacion('wa', this.notificaciones_wa.slice(0, this.empresa.notificaciones_wa))
              payload = {
                medio: 'wa',
                medios_notificacion,
              }
              await this.putMediosNotificacion(payload)
              this.$toast.success('Se actualizó la información correctamente.')
              this.notificaciones_wa = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_wa)
              this.$refs.formNotificaciones_wa.resetValidation()
              break
            case 'correo':
              medios_notificacion = this.fromArrayString2ArrayNotificacion('correo', this.notificaciones_correo.slice(0, this.empresa.notificaciones_sms))
              payload = {
                medio: 'correo',
                medios_notificacion,
              }
              await this.putMediosNotificacion(payload)
              this.$toast.success('Se actualizó la información correctamente.')
              this.notificaciones_correo = this.fromArrayNotificacion2ArrayString(this.medios_notificacion_correo)
              this.$refs.formNotificaciones_correo.resetValidation()
              break
          }
        } catch (e) {
          // this.$toast.error('No se pudo actualizar su información, intentelo nuevamente en unos momentos.')
        } finally {
          this.botonesLoading.notificaciones_correo = false
          this.botonesLoading.notificaciones_wa = false
          this.botonesLoading.notificaciones_sms = false
        }
      },
    },
  }
</script>

<style scoped>

</style>
