<template>
  <v-container
    fluid
    id="user-profile"
    tag="section"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        lg="6"
        sm="8"
        v-if="user && empresa"
      >
        <base-material-card
          :avatar=" empresa != '' ? empresa.logo : ''"
          :avatar-upload="user.propietario"
          class="v-card-profile"
          color="primary"
        >
          <v-card-text class="text-center container justify-start">
            <h3 class="row justify-center display-4 font-weight-light mb-3 white--text">
              {{ empresa.nombre }}
            </h3>
            <h4 class="row justify-center headline font-weight-light mb-3 grey--text">
              {{ user.first_name }} {{ user.last_name }}
            </h4>
            <section class="row mt-12">
              <p class="text-center col-12">
                {{ empresa.descripcion }}
              </p>
            </section>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col
        class="columna-derecha"
        lg="6"
        md="8"
        sm="10"
        v-if="user.propietario"
      >
        <editar-empresa
          v-if="user.propietario"
        />
        <editar-perfil
          v-if="user"
        />
        <editar-alarmas
          v-if="user.propietario && alertasActivas"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import editarPerfil from '../components/perfil/editarPerfil'
  import editarAlarmas from '../components/perfil/editarAlarmas'
  import editarEmpresa from '../components/perfil/editarEmpresa'

  export default {
    components: {
      'editar-perfil': editarPerfil,
      'editar-alarmas': editarAlarmas,
      'editar-empresa': editarEmpresa,
    },
    methods: {
      ...mapActions('perfil', ['fetchEmpresa', 'fetchUser']),
    },
    async beforeMount () {
      await this.fetchUser()
      await this.fetchEmpresa()
    },
    computed: {
      ...mapState('perfil', ['user', 'empresa']),
      alertasActivas () {
        if (this.empresa === null) return false
        return this.empresa.notificaciones_wa > 0 || this.empresa.notificaciones_correo > 0 || this.empresa.notificaciones_sms > 0
      },
    },

  }
</script>
<style lang="scss" scoped>
  @import "src/sass/mixins";

  .columna-derecha {
    @include lg {
      overflow-y: scroll;
      height: 90vh;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  /*@include lg {
    .columna-derecha {
    }

    .columna-derecha::-webkit-scrollbar {
      display: none;
    }
  }*/
</style>
